// Generated by Framer (a4439fa)

import { addFonts, ComponentViewportProvider, cx, CycleVariantState, getFonts, optimizeAppear, useComponentViewport, useLocaleInfo, useVariantState, withCSS, withFX } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import Ticker from "https://framerusercontent.com/modules/B2xAlJLcN0gOnt11mSPw/XVUmpmPn1EPL0dzocT35/Ticker.js";
const TickerFonts = getFonts(Ticker);
const MotionDivWithFX = withFX(motion.div);

const cycleOrder = ["aObg4Gu9w"];

const serializationHash = "framer-DFQR5"

const variantClassNames = {aObg4Gu9w: "framer-v-1ms2dtd"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const transition2 = {damping: 30, delay: 0, mass: 1, stiffness: 400, type: "spring"}

const animation = {opacity: 0, rotate: 0, rotateX: 0, rotateY: 0, scale: 1, skewX: 0, skewY: 0, transition: transition2, x: 0, y: 0}

const animation1 = {opacity: 1, rotate: 0, rotateX: 0, rotateY: 0, scale: 1, skewX: 0, skewY: 0, transition: transition2, x: 0, y: 0}

const animation2 = {opacity: 0.001, rotate: 0, rotateX: 0, rotateY: 0, scale: 1, skewX: 0, skewY: 0, x: 0, y: 0}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "aObg4Gu9w", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1ms2dtd", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"aObg4Gu9w"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{backgroundColor: "var(--token-883db8d8-bd80-4031-8428-cb87c313135d, rgb(207, 195, 249))", ...style}}><ComponentViewportProvider ><MotionDivWithFX __perspectiveFX={false} __smartComponentFX __targetOpacity={1} animate={optimizeAppear("animate", "1nefuyv", animation1, "1ms2dtd")} className={"framer-1nefuyv-container"} data-framer-appear-id={"1nefuyv"} exit={animation} initial={optimizeAppear("initial", "1nefuyv", animation2, "1ms2dtd")} layoutDependency={layoutDependency} layoutId={"B8_wAklZF-container"}><Ticker alignment={"center"} direction={"left"} fadeOptions={{fadeAlpha: 0, fadeContent: false, fadeInset: 0, fadeWidth: 25, overflow: false}} gap={0} height={"100%"} hoverFactor={1} id={"B8_wAklZF"} layoutId={"B8_wAklZF"} padding={10} paddingBottom={10} paddingLeft={20} paddingPerSide paddingRight={20} paddingTop={10} sizingOptions={{heightType: true, widthType: true}} slots={[]} speed={90} style={{height: "100%", width: "100%"}} width={"100%"}/></MotionDivWithFX></ComponentViewportProvider></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-DFQR5.framer-hyaa0o, .framer-DFQR5 .framer-hyaa0o { display: block; }", ".framer-DFQR5.framer-1ms2dtd { height: 60px; overflow: hidden; position: relative; width: 1200px; }", ".framer-DFQR5 .framer-1nefuyv-container { bottom: -15px; flex: none; left: -151px; position: absolute; right: -11px; top: -16px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 60
 * @framerIntrinsicWidth 1200
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerLPS7qjD6T: React.ComponentType<Props> = withCSS(Component, css, "framer-DFQR5") as typeof Component;
export default FramerLPS7qjD6T;

FramerLPS7qjD6T.displayName = "roi french slider";

FramerLPS7qjD6T.defaultProps = {height: 60, width: 1200};

addFonts(FramerLPS7qjD6T, [{explicitInter: true, fonts: []}, ...TickerFonts], {supportsExplicitInterCodegen: true})